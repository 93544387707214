/* eslint-disable @typescript-eslint/no-loop-func */
import { Flex, Text, Tooltip } from '@mantine/core';
import {
  CellClickedEvent,
  ColDef,
  ColumnEvent,
  ColumnState,
  DetailGridInfo,
  GetDetailRowDataParams,
  GridApi,
  GridReadyEvent,
  ICellRendererParams,
  IDetailCellRendererParams,
  InitialGroupOrderComparatorParams,
  IRowNode,
  ModuleRegistry,
  RowDataUpdatedEvent,
  RowGroupOpenedEvent,
  RowNode,
} from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useDidUpdate, useDisclosure, useLocalStorage, useToggle } from '@mantine/hooks';
import {
  BarkerCoreEnumsPointOfSale,
  BarkerCoreEnumsPricerStatus,
  BarkerCoreModelsInventoryEvent,
  BarkerCoreModelsInventoryListingVendorPropertiesDtiPortal,
  BarkerCoreModelsSalesListingSoldVendorPropertiesTradeDeskPos,
  getGetApiSalesQueryKey,
  SystemTextJsonPatchOperationsOperation,
  useGetApiSales,
  usePutApiInventoryEventsEventIdViewed,
  usePutApiInventoryListingsListingIdViewed,
} from '../../api';
import { BarkerEventListing, BarkerEventListingWithPending, FilterMode } from '../../types';
import {
  filterModeAtom,
  mergedEventListingsAtom,
  mergedEventListingsUnfilteredRulesAtom,
  pendingListingUpdatesAtom,
  previousSelectedEventAtom,
  previousSelectedTenantListingIdAtom,
  rowDraggingAtom,
  selectedEventAtom,
  selectedMergedListingAtom,
  selectedTenantListingIdAtom,
  showEventPerformanceAtom,
  transientGlobalStateAtom,
  updateListingsAtom,
} from '../../data/atoms';
import { useInventoryDragEvents } from './Inventory.dragdrop';
import { RePrice } from './Inventory.RePrice';
import { useRuleState } from '../../data/RuleState';
import { formatCurrency } from '../../utils/formatters';
import { Properties } from './Inventory.Properties';
import yasml from '@thirtytech/yasml';
import { InventoryAction } from './Inventory.Action';
import { ListingIdDisplay } from './Inventory.ListingIdDisplay';
import { useGlobalState } from '../../data/GlobalState';
import { SortItem } from './Inventory.SortItem';
import { getNestedProperty } from '../../utils/getNestedProperty';
import { useSearchParams } from 'react-router-dom';
import { BNTags } from '../../components/Tags/Tags';
import { matchesRowFilter } from '../MarketListings/marketListingsFilter';
import { DragCell } from './Inventory.DragCell';
import { useBulkState } from '../../data/BulkState';
import { InlineSplitsToggle } from './Inventory.InlineSplits';
import { getPointOfSaleSplitType, setSplitsModelForUpdate } from '../../components/Splits/Splits.utils';
import { InventoryOnHold } from './Inventory.OnHold';
import { Broadcasting } from './Inventory.Broadcasting';
import { SaleDetails } from './Inventory.SaleDetails';
import { BNEmptyState } from '../../components/EmptyState/EmptyState';
import { useListingState } from '../../data/ListingState';
import { TenantIdListingId } from '../../models/tenantIdListingId';
import { useAppearanceSettings } from './Inventory.AppearanceSettings.hook';
import { useFlag } from '@unleash/proxy-client-react';
import { PriceDisplay } from './Inventory.PriceDisplay';
import { openConfirmModal } from '@mantine/modals';
import BarcodeIcon from '../../components/icons/Barcode';
import { onRefetchInventory } from '../EventSearch/SearchResults.hooks';
import { Attachments } from '../DTI/Attachments/Attachments';
import { isDtiHosted } from '../../utils/whitelabel-consts';
import { range } from '../../utils/price-utils';
import { DTIOnHoldProperty } from '../DTI/OnHold/DTIOnHoldProperty';

dayjs.extend(relativeTime);
ModuleRegistry.registerModules([ClientSideRowModelModule, MasterDetailModule, MenuModule, ColumnsToolPanelModule, RangeSelectionModule]);

export const onNextInventoryItem = new Event('onNextInventoryItem');
export const onPrevInventoryItem = new Event('onPrevInventoryItem');
export const onColumnMoved = new Event('onColumnMoved');
export const onColumnSorted = new Event('onColumnSorted');

const InventoryState = () => {
  const gridRef = useRef<AgGridReact<BarkerEventListing>>(null);
  const detailGridRef = useRef<Partial<AgGridReact<BarkerEventListing>> | undefined>();
  const [gridReady, setGridReady] = useState(false);
  const { currentUser, tenants } = useGlobalState('currentUser', 'tenants');
  const { patchListing } = useListingState('patchListing');
  const [showSort, toggleShowSort] = useToggle();
  const [selectedListingId, setSelectedListingId] = useAtom(selectedTenantListingIdAtom);
  const selectedListing = useAtomValue(selectedMergedListingAtom);
  const [inventoryQuickFilter, setInventoryQuickFilter] = useState<string>();
  const [filter, setFilter] = useState(''); // Used for input state of semi controlled input
  const [transientGlobalState, setTransientGlobalState] = useAtom(transientGlobalStateAtom);
  const pendingUpdates = useAtomValue(pendingListingUpdatesAtom);
  const [searchParams] = useSearchParams();
  const selectedEvent = useAtomValue(selectedEventAtom);
  const { setShowBulkOptions, showBulkOptions } = useBulkState('showBulkOptions', 'setShowBulkOptions');
  const isShowingEventPerformance = useAtomValue(showEventPerformanceAtom);
  const previousSelectedListingId = useAtomValue(previousSelectedTenantListingIdAtom);
  const previousSelectedEvent = useAtomValue(previousSelectedEventAtom);
  const appearanceSettings = useAppearanceSettings();
  const [showPurchaseModal, { open: openPurchaseModal, close: _closePurchaseModal }] = useDisclosure();
  const [initialPurchaseEvent, setInitialPurchaseEvent] = useState<BarkerCoreModelsInventoryEvent>();

  const closePurchaseModal = useCallback(
    (bypass: boolean = false) => {
      if (bypass) {
        _closePurchaseModal();
        setInitialPurchaseEvent(undefined);
        document.dispatchEvent(onRefetchInventory);
        return;
      }
      openConfirmModal({
        title: 'Are you sure you want to quit?',
        children: (
          <Text size="sm" pb="xs">
            You will lose any information entered for new inventory.
          </Text>
        ),
        labels: { confirm: 'Quit & Cancel Order', cancel: 'Keep Editing' },
        confirmProps: { className: 'confirmButton', variant: 'filled', color: 'red', size: 'sm' },
        cancelProps: { className: 'cancelButton', variant: 'default', color: 'gray', size: 'sm' },
        closeButtonProps: { size: 'md' },
        onConfirm() {
          _closePurchaseModal();
          setInitialPurchaseEvent(undefined);
        },
      });
    },
    [_closePurchaseModal],
  );

  const hideCostFlag = useFlag('hide-cost');

  const toggleBulkOptions = useCallback(() => {
    setShowBulkOptions((p) => !p);
  }, [setShowBulkOptions]);
  const defaultColDef = useMemo<ColDef>(
    () => ({
      width: 170,
      flex: 1,
      resizable: showSort,
      suppressMenu: true,
      suppressMovable: !showSort,
      sortable: showSort,
      filter: true,
    }),
    [showSort],
  );
  const [inventoryGridState, setInventoryGridState, clearInventoryGridState] = useLocalStorage<ColumnState[]>({
    key: 'inventory-grid-state',
    getInitialValueInEffect: false,
  });

  const [eventGridState, setEventGridState, clearEventGridState] = useLocalStorage<SortItem[]>({
    key: 'inventory-grid-event-sort',
    getInitialValueInEffect: false,
  });

  useEffect(() => {
    if (searchParams.has('resetGrid')) {
      clearInventoryGridState();
      clearEventGridState();
    }
  }, [clearEventGridState, clearInventoryGridState, searchParams]);

  const { validateDirtyRuleState, triggerSaveRule } = useRuleState('validateDirtyRuleState', 'triggerSaveRule');
  const { onDetailMasterRowDragEnd, onMasterRowDragEnd, onMasterRowDragMove, onDetailRowDragEnd } = useInventoryDragEvents({ gridRef, detailGridRef });

  const updateInventoryListing = useSetAtom(updateListingsAtom);

  const mergedEventListings = useAtomValue(mergedEventListingsAtom);
  const [filteredListings, setFilteredListings] = useState<BarkerEventListing[]>(mergedEventListings);

  useDidUpdate(() => {
    if (inventoryQuickFilter) {
      let matchingListings = [...mergedEventListings];

      const andParts = inventoryQuickFilter.split(' ');

      andParts.forEach((andPart) => {
        const andListings: BarkerEventListing[] = [];

        const orParts = andPart.split(',');

        orParts.forEach((orPart) => {
          const rangeParts = orPart.split('-');

          if (
            rangeParts.length === 2 &&
            ((!isNaN(Number(rangeParts[0])) && !isNaN(Number(rangeParts[1]))) ||
              ([...rangeParts[0]].every((char) => char === rangeParts[0][0]) && [...rangeParts[1]].every((char) => char === rangeParts[1][0])))
          ) {
            // Either the part is a number of it is a string of the same character, so check sections or rows
            andListings.push(...matchingListings.filter((listing) => matchesRowFilter(listing.section, [orPart]) || matchesRowFilter(listing.row, [orPart])));
          } else if (orPart.length <= 3 && (!isNaN(Number(orPart)) || [...orPart].every((char) => char === orPart[0]))) {
            // Either the part is a number or it is a string of the same character, so check sections or rows
            andListings.push(...matchingListings.filter((listing) => matchesRowFilter(listing.section, [orPart]) || matchesRowFilter(listing.row, [orPart])));
          } else {
            // Otherwise, check event name, venue name, city, state, or section
            andListings.push(
              ...matchingListings.filter(
                (listing) =>
                  listing.event.name.toLowerCase().includes(orPart.toLowerCase()) ||
                  listing.event.venue.name.toLowerCase().includes(orPart.toLowerCase()) ||
                  listing.event.venue.city?.toLowerCase().includes(orPart.toLowerCase()) ||
                  listing.event.venue.state?.toLowerCase().includes(orPart.toLowerCase()) ||
                  listing.section.toLowerCase().includes(orPart.toLowerCase()),
              ),
            );
          }
        });

        matchingListings = andListings;
      });

      const additionalListings = mergedEventListings.filter((listing) =>
        matchingListings.some((match) => match.ruleId !== null && match.ruleId === listing.ruleId && match.listingId !== listing.listingId),
      );

      additionalListings?.forEach((listing) => {
        if (!matchingListings.some((match) => match.listingId === listing.listingId)) {
          matchingListings.push(listing);
        }
      });

      setFilteredListings([...matchingListings]);
    } else {
      setFilteredListings(mergedEventListings);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inventoryQuickFilter, mergedEventListings, selectedListingId]);

  const { mutate: updateLastViewedEventId } = usePutApiInventoryEventsEventIdViewed({
    axios: {
      headers: {
        'x-tenant-id': selectedListing?.tenantId,
      },
    },
  });
  const { mutate: updateLastViewedListingId } = usePutApiInventoryListingsListingIdViewed({
    axios: {
      headers: {
        'x-tenant-id': selectedListing?.tenantId,
      },
    },
  });

  const closeExpandedNode = useCallback((tenantIdListingId: TenantIdListingId) => {
    gridRef.current?.api.getRowNode(tenantIdListingId.toString())?.setExpanded(false);
  }, []);

  const mergedEventListingsRef = useRef(mergedEventListings);
  const tenantsRef = useRef(tenants);

  useEffect(() => {
    mergedEventListingsRef.current = mergedEventListings;
  }, [mergedEventListings]);

  useEffect(() => {
    tenantsRef.current = tenants;
  }, [tenants]);

  // Note: Function trapped in grid. Can't useCallback or mergedEventListings will always be empty.
  const updateBroadcasting = async (tenantId: string, listingId: string, isBroadcasting: boolean) => {
    const listing = mergedEventListingsRef.current?.find((l) => l.tenantId === tenantId && l.listingId === listingId);
    const updates: SystemTextJsonPatchOperationsOperation[] = [
      {
        op: 'Replace',
        path: '/isBroadcasting',
        value: isBroadcasting,
      },
    ];
    if (isBroadcasting && !listing?.splits) {
      const tenantPointOfSale = tenantsRef.current?.find((t) => t.tenantId === listing?.tenantId)?.pointOfSaleId;
      if (tenantPointOfSale) {
        const splitUpdate = setSplitsModelForUpdate(tenantPointOfSale, getPointOfSaleSplitType(tenantPointOfSale, listing?.splits));
        if (splitUpdate) {
          updates.push(splitUpdate);
        }
      }
    }
    const response = await patchListing({
      tenantId,
      listingId,
      data: updates,
    });
    updateInventoryListing(response.data);
  };

  interface DragCellRendererParams extends ICellRendererParams<BarkerEventListing> {
    showBulkOptions: boolean;
  }

  type BNInventoryColumn = ColDef<BarkerEventListing> & {
    exclude?: boolean;
    allowSorting?: boolean;
    sort?: 'asc' | 'desc' | null;
    sortIndex?: number | null;
  };
  const [selectedHistoryListing, setSelectedHistoryListing] = useState<{
    listing: BarkerEventListing | null;
    isOpen: boolean;
  }>({ listing: null, isOpen: false });

  const isTradeDeskUser = tenants?.some((t) => t.pointOfSaleId === BarkerCoreEnumsPointOfSale.TradeDeskPos) ?? false;

  // @ts-ignore
  const dtiDefaultColumnDefs: BNInventoryColumn[] = useMemo(() => {
    if (!isDtiHosted) {
      return [];
    }

    if (tenants === undefined || !tenants.some((t) => t.pointOfSaleId === 'DtiPortal')) {
      return [];
    }

    return [
      {
        colId: 'attachments',
        allowSorting: false,
        headerName: 'Assets',
        suppressCellFlash: true,
        valueGetter: (params) =>
          JSON.stringify({
            quantity: params.data?.quantityRemaining,
            vendorProperties: params.data?.vendorProperties,
          }),
        cellRenderer: (params: ICellRendererParams<BarkerEventListing>) => {
          if (params.data && !params.data?.listingId.endsWith('sales') && !params.data?.sale) {
            const { quantityRemaining, vendorProperties } = params.data;

            const dtiProperties =
              vendorProperties?.pointOfSaleId === BarkerCoreEnumsPointOfSale.DtiPortal
                ? (vendorProperties as BarkerCoreModelsInventoryListingVendorPropertiesDtiPortal)
                : undefined;

            if (!dtiProperties) {
              return null;
            }

            return (
              <Attachments
                quantity={quantityRemaining}
                numBarcodes={dtiProperties.numBarcodes}
                numPdfs={dtiProperties.numPdfs}
                num1Tmds={dtiProperties.num1TMDs}
                numTransferUrls={dtiProperties.numTransferUrls}
              />
            );
          }
          return null;
        },
        width: 120,
        minWidth: 120,
      },
      {
        // @ts-ignore
        field: 'vendorProperties.location',
        headerName: 'Location',
        width: 150,
        hide: true,
      },
      {
        // @ts-ignore
        field: 'vendorProperties.purchaserEmail',
        headerName: 'Purchaser',
        width: 200,
        hide: true,
        // @ts-ignore
        tooltipField: 'vendorProperties.purchaserEmail',
      },
      {
        // @ts-ignore
        field: 'vendorProperties.accountInfo',
        headerName: 'Account Info',
        width: 200,
        hide: true,
        // @ts-ignore
        tooltipField: 'vendorProperties.accountInfo',
      },
      {
        // @ts-ignore
        field: 'vendorProperties.orderNumber',
        headerName: 'Order #',
        width: 200,
        hide: true,
        // @ts-ignore
        tooltipField: 'vendorProperties.orderNumber',
      },
      {
        // @ts-ignore
        colId: 'cardInfo',
        headerName: 'CC Info',
        valueGetter: (params) => {
          if (params.data && params.data.vendorProperties?.pointOfSaleId === BarkerCoreEnumsPointOfSale.DtiPortal) {
            const { ccCardInfo, ccPurchaserInfo } = params.data.vendorProperties as BarkerCoreModelsInventoryListingVendorPropertiesDtiPortal;
            return `${ccCardInfo === '0' ? '' : ccCardInfo ?? ''} ${ccPurchaserInfo ?? ''}`.trim();
          }
          return '';
        },
        width: 150,
        hide: true,
        // @ts-ignore
        tooltipValueGetter: (params) => {
          if (params.data && params.data.vendorProperties?.pointOfSaleId === BarkerCoreEnumsPointOfSale.DtiPortal) {
            const { ccCardInfo, ccPurchaserInfo } = params.data.vendorProperties as BarkerCoreModelsInventoryListingVendorPropertiesDtiPortal;
            return `${ccCardInfo === '0' ? '' : ccCardInfo ?? ''} ${ccPurchaserInfo ?? ''}`.trim();
          }
          return '';
        },
      },
    ] satisfies BNInventoryColumn[];
  }, [tenants]);

  const defaultColumnDefs: BNInventoryColumn[] = useMemo(
    () =>
      [
        { field: 'tenantIdEventId', allowSorting: false, rowGroup: true, hide: true, exclude: true, sortable: false },
        {
          colId: 'dragCell',
          minWidth: 38,
          sortable: false,
          allowSorting: false,
          width: 38,
          maxWidth: 38,
          rowDrag: false,
          suppressCellFlash: true,
          exclude: true,
          cellRendererParams: {
            showBulkOptions,
          },
          valueGetter: (params) => JSON.stringify({ ruleCount: params.data?.ruleCount, showBulkOptions }),
          cellRenderer: (params: DragCellRendererParams) => {
            if (!params.data?.sale && params.data) {
              return (
                <DragCell
                  listingId={params.data.listingId}
                  tenantId={params.data?.tenantId}
                  bulkMode={params.showBulkOptions}
                  node={params.node}
                  level={params.node.level}
                  ruleCount={params.data.ruleCount || 0}
                  isListingOnHold={params.data.quantityReserved > 0}
                  registerRowDragger={params.registerRowDragger}
                />
              );
            }
            return null;
          },
          cellStyle: { textAlign: 'left' },
        },
        {
          field: 'section',
          headerName: 'Section',
          rowDrag: false,
          minWidth: 80,
          width: 160,
          sort: 'asc',
          sortIndex: 0,
          cellStyle: { textAlign: 'left' },
          comparator: (a, b) => a?.localeCompare(b, undefined, { numeric: true }),
          tooltipField: 'section',
        },
        {
          field: 'listingId',
          headerName: 'Listing ID',
          minWidth: 90,
          width: 90,
          suppressCellFlash: true,
          comparator: (_valueA, _valueB, nodeA, nodeB) => (nodeA.data?.listingId || '').localeCompare(nodeB.data?.listingId || ''),
          valueGetter: (params) => JSON.stringify({ listingId: params.data?.listingId, ruleId: params.data?.ruleId }),
          cellStyle: (params) => ({
            textAlign: 'center',
            color: import.meta.env.DEV && params.data?.ruleId ? 'var(--colors-brandcolor-5)' : '',
          }),
          cellRenderer: (params: ICellRendererParams<BarkerEventListing>) => <ListingIdDisplay listing={params.data!} />,
          hide: true,
        },
        {
          field: 'row',
          headerName: 'Row',
          minWidth: 70,
          width: 70,
          sort: 'asc',
          sortIndex: 1,
          cellStyle: { textAlign: 'center' },
          comparator: (a, b) => a?.localeCompare(b, undefined, { numeric: true }),
        },
        {
          colId: 'seats',
          minWidth: 90,
          width: 90,
          headerName: 'Seats',
          cellStyle: { textAlign: 'center' },
          valueGetter: (params) => {
            if (params.data) {
              const { seatFrom, seatThru, quantityRemaining } = params.data;
              return quantityRemaining > 1 ? `${seatFrom}-${seatThru}` : seatFrom;
            }

            return '';
          },
        },
        {
          field: 'quantityRemaining',
          minWidth: 70,
          width: 70,
          headerName: 'Qty',
          sort: 'desc',
          sortIndex: 2,
          cellStyle: { textAlign: 'center' },
        },
        {
          minWidth: 100,
          width: 100,
          colId: 'splits',
          headerName: 'Splits',
          cellStyle: { textAlign: 'center', textTransform: 'capitalize' },
          hide: true,
          valueGetter: (params) => {
            if (params.data && !params.data?.listingId.endsWith('sales')) {
              return JSON.stringify({ splits: params.data.splits });
            }
            return '';
          },
          cellRenderer: (params: ICellRendererParams<BarkerEventListing>) => {
            if (params.data?.listingId.endsWith('sales') || params.data?.sale) {
              return null;
            }
            return params.data ? <InlineSplitsToggle listing={params.data} /> : params.value;
          },
        },
        {
          field: 'isBroadcasting',
          headerName: 'Broadcasting',
          hide: true,
          minWidth: 50,
          width: 50,
          cellStyle: { display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: 0, paddingRight: 0 },
          cellRenderer: (params: ICellRendererParams<BarkerEventListing>) => {
            if (params.data) {
              const { isBroadcasting, listingId, tenantId, ruleCount } = params.data;
              return <Broadcasting isBroadcasting={isBroadcasting} listingId={listingId} tenantId={tenantId} updateBroadcasting={updateBroadcasting} ruleCount={ruleCount} />;
            }
            return null;
          },
        },
        {
          field: 'quantityReserved',
          headerName: 'On Hold',
          hide: true,
          minWidth: 50,
          width: 50,
          cellStyle: { display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: 0, paddingRight: 0 },
          cellRenderer: (params: ICellRendererParams<BarkerEventListing>) => {
            if (params.data) {
              const { quantityRemaining, quantityReserved } = params.data;

              if (isDtiHosted && params.data.vendorProperties?.pointOfSaleId === 'DtiPortal') {
                const { ownerId } = params.data.vendorProperties as BarkerCoreModelsInventoryListingVendorPropertiesDtiPortal;
                const { listingId } = params.data;
                return <DTIOnHoldProperty quantityReserved={quantityReserved} quantityRemaining={quantityRemaining} accountId={ownerId} listingId={listingId} />;
              }

              return <InventoryOnHold quantityRemaining={quantityRemaining} quantityReserved={quantityReserved} />;
            }

            return null;
          },
        },
        {
          field: 'unitCost',
          minWidth: 110,
          width: 110,
          headerName: 'Cost',
          valueFormatter: (params) => formatCurrency(params.value),
          cellStyle: { textAlign: 'right', fontVariantNumeric: 'tabular-nums', letterSpacing: -0.65 },
          exclude: hideCostFlag,
          hide: hideCostFlag ? true : undefined,
        },
        {
          field: 'unitFace',
          minWidth: 110,
          width: 110,
          headerName: 'Face',
          valueFormatter: (params) => formatCurrency(params.value),
          cellStyle: { textAlign: 'right', fontVariantNumeric: 'tabular-nums', letterSpacing: -0.65 },
          hide: true,
        },
        {
          field: 'unitPrice',
          minWidth: 110,
          width: 110,
          headerName: 'Price',
          suppressKeyboardEvent: () => true,
          comparator: (_valueA, _valueB, nodeA, nodeB) => (nodeA.data?.unitPrice || 0) - (nodeB.data?.unitPrice || 0),
          valueGetter: (params) =>
            JSON.stringify({
              unitPrice: params.data?.unitPrice,
              pricerStatusId: params.data?.pricerStatusId,
            }),
          cellStyle: { textAlign: 'right', fontVariantNumeric: 'tabular-nums', letterSpacing: -0.65 },
          cellRenderer: (params: ICellRendererParams<BarkerEventListing>) => {
            if (params.data && !params.data?.listingId.endsWith('sales')) {
              return (
                <PriceDisplay
                  gridRef={gridRef}
                  isDetailRow={params.node.level === 0}
                  listingId={params.data.listingId}
                  tenantId={params.data.tenantId}
                  pricerStatusId={params.data.pricerStatusId}
                  value={params.data.unitPrice}
                  cost={params.data.unitCost}
                  isListingOnHold={params.data.quantityReserved > 0}
                />
              );
            }
            return null;
          },
        },
        {
          minWidth: 100,
          width: 100,
          colId: 'repriced',
          headerName: 'Repriced',
          comparator: (_valueA, _valueB, nodeA, nodeB) => {
            const { pricedAt: ApricedAt, posPricedAt: AposPricedAt } = nodeA?.data || ({} as BarkerEventListing);
            const { pricedAt: BpricedAt, posPricedAt: BposPricedAt } = nodeB?.data || ({} as BarkerEventListing);
            const AposNewerThanPricedAt = !ApricedAt || dayjs(AposPricedAt).isAfter(ApricedAt);
            const BposNewerThanPricedAt = !BpricedAt || dayjs(BposPricedAt).isAfter(BpricedAt);
            const ADate = AposNewerThanPricedAt ? AposPricedAt : ApricedAt;
            const BDate = BposNewerThanPricedAt ? BposPricedAt : BpricedAt;
            return (
              // @ts-ignore
              dayjs(ADate || new Date(null))
                .toDate()
                .getTime() - // @ts-ignore
              dayjs(BDate || new Date(null))
                .toDate()
                .getTime()
            );
          },
          valueGetter: (params) => {
            if (params.data) {
              const { pricedAt, posPricedAt, previousPrice, unitPrice, latestPrice } = params.data;
              const posNewerThanPricedAt = !pricedAt || dayjs(posPricedAt).isAfter(pricedAt);
              return JSON.stringify({
                previousPrice,
                unitPrice,
                dateValue: posNewerThanPricedAt ? posPricedAt : pricedAt,
                latestPrice,
              });
            }
            return JSON.stringify({ previousPrice: 0, unitPrice: 0, dateValue: '', latestPrice: 0 });
          },
          cellRenderer: (params: ICellRendererParams) => (
            <RePrice
              posPricedAt={params.data.posPricedAt}
              pricedAt={params.data.pricedAt}
              unitPrice={params.data.unitPrice}
              previousPrice={params.data.previousPrice}
              pricedBy={params.data.pricedBy}
              latestPrice={params.data.latestPrice}
            />
          ),
          cellStyle: { textAlign: 'center' },
        },
        {
          colId: 'repricedDetails',
          headerName: 'Repriced Text',
          hide: true,
          minWidth: 120,
          allowSorting: false,
          valueGetter: (params) => {
            if (params.data) {
              const { pricedAt, posPricedAt, previousPrice, unitPrice, latestPrice } = params.data;
              const posNewerThanPricedAt = !pricedAt || dayjs(posPricedAt).isAfter(pricedAt);
              return JSON.stringify({
                previousPrice,
                unitPrice,
                dateValue: posNewerThanPricedAt ? posPricedAt : pricedAt,
                latestPrice,
              });
            }
            return JSON.stringify({ previousPrice: 0, unitPrice: 0, dateValue: '', latestPrice: 0 });
          },
          cellRenderer: (params: ICellRendererParams) => (
            <RePrice
              posPricedAt={params.data.posPricedAt}
              pricedAt={params.data.pricedAt}
              unitPrice={params.data.unitPrice}
              previousPrice={params.data.previousPrice}
              pricedBy={params.data.pricedBy}
              latestPrice={params.data.latestPrice}
              textOnly
            />
          ),
        },
        {
          colId: 'properties',
          allowSorting: false,
          headerName: 'Properties',
          suppressCellFlash: true,
          valueGetter: (params) =>
            JSON.stringify({
              isBroadcasting: params.data?.isBroadcasting,
              tags: params.data?.tags,
              ruleCount: params.data?.ruleCount,
              quantityReserved: params.data?.quantityReserved,
              quantityRemaining: params.data?.quantityRemaining,
              numBarcodes: params.data?.numBarcodes,
              vendorProperties: params.data?.vendorProperties,
            }),
          cellRenderer: (params: ICellRendererParams<BarkerEventListing>) => {
            if (params.data && !params.data?.listingId.endsWith('sales') && !params.data?.sale) {
              const { isBroadcasting, tenantId, listingId, ruleCount, tags, quantityReserved, quantityRemaining, numBarcodes, vendorProperties } = params.data;

              const dtiProperties =
                isDtiHosted && vendorProperties?.pointOfSaleId === BarkerCoreEnumsPointOfSale.DtiPortal
                  ? (vendorProperties as BarkerCoreModelsInventoryListingVendorPropertiesDtiPortal)
                  : undefined;

              return (
                <Properties
                  tenantId={tenantId}
                  listingId={listingId}
                  isBroadcasting={isBroadcasting}
                  updateBroadcasting={updateBroadcasting}
                  tags={tags}
                  quantityReserved={quantityReserved}
                  quantityRemaining={quantityRemaining}
                  ruleCount={ruleCount}
                  numBarcodes={dtiProperties ? 0 : numBarcodes} // DTI has its own barcodes column inside of attachments
                  vendorProperties={dtiProperties}
                />
              );
            }
            return null;
          },
          width: 120,
          minWidth: 120,
        },
        {
          colId: 'inHand',
          headerName: 'In Hand',
          width: 110,
          hide: true,
          cellStyle: { textAlign: 'center' },
          valueGetter: (params) => JSON.stringify({ isInHand: params.data?.isInHand, inHandDate: params.data?.inHandDate }),
          valueFormatter: (params) => {
            if (!params.data) return '';

            const { isInHand, inHandDate } = params.data;

            return isInHand ? 'Now' : !inHandDate ? '' : dayjs(inHandDate).format('MM/DD/YYYY');
          },
        },
        {
          field: 'floorPrice',
          headerName: 'Floor Price',
          hide: true,
          minWidth: 110,
          width: 110,
          valueFormatter: (params) => formatCurrency(params.value),
          cellStyle: { textAlign: 'right' },
        },
        {
          field: 'ceilingPrice',
          headerName: 'Ceiling Price',
          hide: true,
          minWidth: 110,
          width: 110,
          valueFormatter: (params) => formatCurrency(params.value),
          cellStyle: { textAlign: 'right' },
        },
        { field: 'internalNotes', headerName: 'Internal Notes', hide: !isDtiHosted, minWidth: 120, tooltipField: 'internalNotes' },
        { field: 'externalNotes', headerName: 'External Notes', hide: !isDtiHosted, minWidth: 120, tooltipField: 'externalNotes' },
        {
          field: 'tags',
          headerName: 'Tags',
          hide: true,
          minWidth: 120,
          valueGetter: (params) => params.data?.tags?.join(',') ?? '',
          cellRenderer: (params: ICellRendererParams) => <BNTags tags={params.data?.tags} />,
        },
        {
          field: 'numBarcodes',
          headerName: 'Barcodes',
          hide: true,
          minWidth: 50,
          width: 50,
          cellStyle: { display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: 0, paddingRight: 0 },
          valueGetter: (params) => params.data?.numBarcodes ?? 0,
          cellRenderer: (params: ICellRendererParams<BarkerEventListing>) => {
            if ((params.data?.numBarcodes ?? 0) > 0) {
              return (
                <Tooltip label="Barcoded" withinPortal withArrow>
                  <Flex w={24} h={24} align="center" justify="center" style={{ borderRadius: 28 }}>
                    <BarcodeIcon size={16} />
                  </Flex>
                </Tooltip>
              );
            }
            return null;
          },
        },
        {
          field: 'cachedAt',
          headerName: 'First Seen',
          hide: true,
          minWidth: 120,
          valueFormatter: (params) => (params.value ? dayjs(params.value).fromNow(true) : 'Unknown'),
          valueGetter: (params) => params.data?.cachedAt?.toString(), // This is to give a constant value to prevent cell flashing / rerendering
          comparator: (a, b) => dayjs(a).unix() - dayjs(b).unix(),
        },
        ...(isTradeDeskUser
          ? ([
              {
                colId: 'externalListingId',
                headerName: 'External ID',
                hide: true,
                width: 120,
                valueGetter: (params) => {
                  if (!params.data) return '';

                  if (params.data.vendorProperties?.pointOfSaleId !== BarkerCoreEnumsPointOfSale.TradeDeskPos) return '';

                  const vendorProperties = params.data.vendorProperties as BarkerCoreModelsSalesListingSoldVendorPropertiesTradeDeskPos;

                  return vendorProperties?.listingExternalId;
                },
              },
            ] as BNInventoryColumn[])
          : []),
        ...(isDtiHosted ? dtiDefaultColumnDefs : []),
        {
          colId: 'action',
          allowSorting: false,
          headerName: 'Action',
          suppressCellFlash: true,
          valueGetter: (params) =>
            JSON.stringify({
              isBroadcasting: params.data?.isBroadcasting,
              ruleId: params.data?.ruleId,
              internalNotes: params.data?.internalNotes,
              externalNotes: params.data?.externalNotes,
              splits: params.data?.splits,
              ruleCount: params.data?.ruleCount,
              quantityReserved: params.data?.quantityReserved,
            }),
          cellRenderer: (params: ICellRendererParams<BarkerEventListing>) => {
            //if (params.data && !params.data.listingId.endsWith('sales') && !params.data.sale) {
            if (params.data) {
              params.eGridCell.parentElement?.setAttribute('aria-label', `Listing ${params.data.listingId} row`);
              return (
                <InventoryAction
                  listing={params.data}
                  updateBroadcasting={updateBroadcasting}
                  setSelectedHistoryListing={setSelectedHistoryListing}
                  ruleId={params.data.ruleId}
                  isAnchorListing={params.data.ruleCount !== undefined && params.data.ruleCount >= 2 && params.node.level !== 0}
                />
              );
            }
            return null;
          },
          pinned: 'right',
          width: 40,
          minWidth: 40,
          maxWidth: 40,
        },
      ] as BNInventoryColumn[],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const columnDefs: ColDef<BarkerEventListing>[] = useMemo(
    () =>
      defaultColumnDefs
        .map((col) => {
          // Clears properties before giving it to AG-Grid to avoid warnings.
          const { allowSorting, exclude, ..._col } = col;
          const savedState = { ...inventoryGridState?.filter((x) => !['dragCell'].includes(x.colId)).find((y) => y.colId === col.colId || y.colId === col.field) };

          if (_col.colId === 'dragCell') {
            _col.cellRendererParams = {
              showBulkOptions,
            };
          }

          return { ..._col, ...savedState } as ColDef<BarkerEventListing>;
        })
        .sort((a, b) => {
          const aIndex = inventoryGridState?.findIndex((x) => x.colId === a.colId);
          const bIndex = inventoryGridState?.findIndex((x) => x.colId === b.colId);

          // If a column isn't in gridState, make it appear at the end, unless it's the action column
          if (bIndex === -1 || aIndex === -1) {
            if (a.colId === 'action') {
              return 1;
            }

            return -1;
          }

          return aIndex - bIndex;
        }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showBulkOptions, showSort, currentUser],
  );

  const onRowGroupToggled = useCallback((event: RowGroupOpenedEvent<BarkerEventListing, any>) => {
    if (!event.expanded) {
      event.api.forEachDetailGridInfo((detailGridInfo) => {
        detailGridInfo?.api?.destroy();
      });
      // @ts-ignore
      gridRef.current?.api.__getAlignedGridService().gridOptionsService.set('alignedGrids', []);
    }
  }, []);

  /**
   * Handles selecting the initial inventory listing for marketplace view when grid is loaded and ready
   * @param event
   */
  const onGridReady = useCallback(
    (event: GridReadyEvent) => {
      (window as any).gridRef = event;
      // Handles hot reloading
      if (selectedListingId.hasValue()) {
        event.api.getRowNode(selectedListingId.toString())?.setSelected(true);
      }

      if (hideCostFlag) {
        event.api.setColumnDefs(columnDefs.filter((col) => col.colId !== 'unitCost'));
      }

      setGridReady(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedListingId, hideCostFlag],
  );

  const updateEventSortFn = useCallback((params: InitialGroupOrderComparatorParams<BarkerEventListing>, sort: SortItem[]) => {
    const { nodeA, nodeB } = params;
    const dataA = nodeA.allLeafChildren[0].data!;
    const dataB = nodeB.allLeafChildren[0].data!;

    if (sort) {
      // eslint-disable-next-line no-restricted-syntax
      for (const sortOption of sort.filter((x) => x.dir !== null)) {
        const aValue = getNestedProperty(dataA, sortOption.key);
        const bValue = getNestedProperty(dataB, sortOption.key);
        if (sortOption.key === 'event.localDateTime') {
          const dateA = dayjs(dataA.event.localDateTime);
          const dateB = dayjs(dataB.event.localDateTime);
          if (!dateA.isSame(dateB)) {
            return dateA[sortOption.dir === 'desc' ? 'isAfter' : 'isBefore'](dateB) ? -1 : 1;
          }
          return dataA.event.name.localeCompare(dataB.event.name) || dataA.event.tenantId.localeCompare(dataB.event.tenantId);
        }
        if (aValue !== bValue && typeof aValue === 'string' && typeof bValue === 'string') {
          if (sortOption.dir === 'asc') {
            return (aValue as string).localeCompare(bValue);
          }
          return (bValue as string).localeCompare(aValue);
        }
        if (aValue !== bValue && typeof aValue === 'number' && typeof bValue === 'number') {
          if (sortOption.dir === 'asc') {
            return aValue > bValue ? 1 : -1;
          }
          return aValue < bValue ? 1 : -1;
        }
      }
    } else {
      const dateA = dayjs(dataA!.event.localDateTime);
      const dateB = dayjs(dataB!.event.localDateTime);
      if (!dateA.isSame(dateB)) {
        return dateA.isBefore(dateB) ? -1 : 1;
      }
    }

    return 0;
  }, []);

  const updateEventSort = useCallback(
    (sort: SortItem[]) => {
      gridRef.current?.api.setInitialGroupOrderComparator((params: InitialGroupOrderComparatorParams<BarkerEventListing>) => updateEventSortFn(params, sort));
      setEventGridState(sort);
      gridRef.current!.api.refreshClientSideRowModel();
    },
    [setEventGridState, updateEventSortFn],
  );

  /**
   * Controls how the grid groups are sorted.
   * @param params
   * @returns
   */
  const groupSortOrderComparator = useCallback(
    (params: InitialGroupOrderComparatorParams<BarkerEventListing>) => updateEventSortFn(params, eventGridState),
    [updateEventSortFn, eventGridState],
  );

  const updateViewedEventAndListing = useCallback(
    async (listing: BarkerEventListing) => {
      if (currentUser?.roleId !== 'ReadOnlyUser') {
        try {
          if (listing.eventId && listing.eventId !== '-1') {
            updateLastViewedEventId({ eventId: listing.eventId });
          }
          updateLastViewedListingId({ listingId: listing.listingId });
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log('Ignore Error for now.');
        }
      }
    },
    [currentUser?.roleId, updateLastViewedEventId, updateLastViewedListingId],
  );

  const modifierKeysFlag = useFlag('modifier-keys-bulk');
  const disableDoubleClicking = useRef(false);
  const filterMode = useAtomValue(filterModeAtom);
  const { setMarkedListings } = useBulkState('setMarkedListings');
  const onCellClickedWhenDirty = useCallback(
    async (
      gridEvent: CellClickedEvent<BarkerEventListing> & {
        node: IRowNode<BarkerEventListing> & {
          beans?: {
            gridApi: GridApi<BarkerEventListing>;
          };
        };
      },
    ) => {
      if (!disableDoubleClicking.current) {
        disableDoubleClicking.current = true;
        if (showBulkOptions && gridEvent.column.getColId() === 'dragCell') {
          disableDoubleClicking.current = false;
          return;
        }
        if (gridEvent.node.data?.listingId.includes('sales')) {
          disableDoubleClicking.current = false;
          return;
        }
        if (filterMode === FilterMode.Locked && gridEvent.column.getColId() === 'unitPrice' && gridEvent.node.data?.pricerStatusId === BarkerCoreEnumsPricerStatus.None) {
          disableDoubleClicking.current = false;
          return;
        }
        if (!selectedListing && gridEvent.column.getColId() === 'unitPrice' && gridEvent.node.data?.pricerStatusId === BarkerCoreEnumsPricerStatus.None) {
          disableDoubleClicking.current = false;
          return;
        }

        if (!showBulkOptions && modifierKeysFlag) {
          const event = gridEvent.event as React.MouseEvent<HTMLDivElement> | null;
          if (event?.ctrlKey) {
            disableDoubleClicking.current = false;
            if (gridEvent.data) {
              setMarkedListings([gridEvent.data.tenantIdListingId]);
            }
            toggleBulkOptions();
            return;
          }
          if (event?.shiftKey) {
            disableDoubleClicking.current = false;

            const lastIndex = gridEvent.node.beans?.gridApi.getRowNode(selectedListingId.toString())?.rowIndex!;
            const currentIndex = gridEvent.node.rowIndex!;
            const listingIdsInRange = range(lastIndex, currentIndex)
              .map((x) => gridEvent.node.beans?.gridApi.getDisplayedRowAtIndex(x)?.data?.tenantIdListingId)
              .filter((x) => x != null && !x.endsWith('sales')) as string[];
            setMarkedListings((prev) => [...prev, ...listingIdsInRange]);
            toggleBulkOptions();
            return;
          }
        }

        if (gridEvent.node.data && gridEvent.node.data.tenantIdListingId !== selectedListingId?.toString()) {
          if (await validateDirtyRuleState()) {
            await triggerSaveRule();
            closeExpandedNode(selectedListingId);
            setSelectedListingId(gridEvent.node.data.tenantIdListingId);
            requestAnimationFrame(() => {
              gridRef.current?.api.ensureNodeVisible(gridEvent.node);
            });
          }
        }
        disableDoubleClicking.current = false;
      }
    },
    [
      closeExpandedNode,
      filterMode,
      modifierKeysFlag,
      selectedListing,
      selectedListingId,
      setMarkedListings,
      setSelectedListingId,
      showBulkOptions,
      toggleBulkOptions,
      triggerSaveRule,
      validateDirtyRuleState,
    ],
  );

  useDidUpdate(() => {
    if (selectedListingId.hasValue()) {
      const node = gridRef.current?.api?.getRowNode(selectedListingId.toString());
      if (node && selectedListing) {
        //Attempt to close any open sales
        const salesNode = gridRef.current?.api?.getRowNode(`${selectedListing.tenantIdEventId}|sales`);
        if (salesNode) {
          salesNode.setExpanded(false);
        }
        updateViewedEventAndListing(selectedListing);
        if (previousSelectedListingId) {
          closeExpandedNode(new TenantIdListingId(previousSelectedListingId));
        }
        node.setSelected(true);
        gridRef.current?.api.ensureNodeVisible(node);
        if (node?.isExpandable()) {
          node.setExpanded(true);
        }
      }
    }
    // @ts-ignore
    gridRef.current?.api.__getAlignedGridService().gridOptionsService.set('alignedGrids', []);
  }, [selectedListingId]);

  useDidUpdate(() => {
    if (previousSelectedEvent) {
      const node = gridRef.current?.api?.getRowNode(`${previousSelectedEvent.tenantId}|${previousSelectedEvent.eventId}|sales`);
      if (node) {
        node.setExpanded(false);
      }
    }
  }, [previousSelectedEvent]);

  useDidUpdate(() => {
    // Refresh cells due to issue with drag handles not showing up.
    // gridRef.current?.api.refreshCells();
    gridRef.current?.api.redrawRows();
  }, [showBulkOptions]);

  /**
   * Used to know when row has been expanded in order to collapse any other expanded rows.
   * Seems like there should be an event for this but unable to find one.
   * @param expandedListingId - Uses this context because innderGridReady happens in same render cycle as {@link detailCellRendererParams}
   * @param params
   */
  const detailGridReady = useCallback(
    (params: GridReadyEvent<BarkerEventListing>) => {
      const dropZoneParams = gridRef.current!.api.getRowDropZoneParams({ onDragStop: onDetailMasterRowDragEnd });
      detailGridRef.current = { api: params.api, columnApi: params.columnApi };
      (window as any).detailsGridApi = params;
      params.api.addRowDropZone(dropZoneParams);
      params.columnApi.applyColumnState({ state: [{ colId: 'ruleTier', sort: 'asc', sortIndex: 0 }] });
      const grids: DetailGridInfo[] = [];
      gridRef.current?.api.forEachDetailGridInfo((grid) => {
        grids.push(grid);
      });
      // @ts-ignore
      gridRef.current?.api.__getAlignedGridService().gridOptionsService.set('alignedGrids', grids);
      // setDetailGridToAlign(grids);
    },
    [onDetailMasterRowDragEnd],
  );

  const soldInventoryGridReady = useCallback((params: GridReadyEvent) => {
    detailGridRef.current = { api: params.api, columnApi: params.columnApi } satisfies Partial<AgGridReact<BarkerEventListing>>;
    (window as any).detailsGridApi = params;
    const grids: DetailGridInfo[] = [];
    gridRef.current?.api.forEachDetailGridInfo((grid) => {
      grids.push(grid);
    });
    // setDetailGridToAlign(grids);
    // @ts-ignore
    gridRef.current?.api.__getAlignedGridService().gridOptionsService.set('alignedGrids', grids);
  }, []);

  const mergedEventListingsUnfilteredRules = useAtomValue(mergedEventListingsUnfilteredRulesAtom);

  const getDetailRowDataResults = useCallback(
    (listingId: string, ruleId: string | null | undefined) => {
      const groupedListings = mergedEventListingsUnfilteredRules
        .filter((x) => x.ruleId === ruleId)
        .sort((a, b) => (a.ruleTier || 0) - (b.ruleTier || 0))
        .filter((x) => x.listingId !== listingId)
        .map((listing) => {
          const hasPendingBroadcast = pendingUpdates.find((x) => x.listingId === listing.listingId && x.property === 'isBroadcasting');
          return { ...listing, pendingBroadcast: hasPendingBroadcast ? hasPendingBroadcast.value : undefined };
        });
      return groupedListings;
    },
    [mergedEventListingsUnfilteredRules, pendingUpdates],
  );

  const getDetailRowData = (params: GetDetailRowDataParams) => {
    if (params.node.data.ruleId) {
      const groupedListings = getDetailRowDataResults(params.node.data.listingId, params.node.data.ruleId);
      params.successCallback(groupedListings);
    } else {
      params.successCallback([]);
    }
  };

  useDidUpdate(() => {
    try {
      const sel = gridRef.current?.api?.getSelectedNodes?.()?.[0];
      if (sel) {
        const { listingId, ruleId } = sel.data as BarkerEventListing;
        // @ts-ignore: TS2341
        if (!detailGridRef.current?.api.destroyCalled && !isShowingEventPerformance) {
          detailGridRef.current?.api?.setRowData(getDetailRowDataResults(listingId, ruleId));
        }
      }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {
      // Ignore this error for now. Should be transient
    }
  }, [pendingUpdates]);

  const gridNodeSelection = useCallback(
    (
      _selectedListingId: string,
      options: {
        node?: RowNode<BarkerEventListing>;
        pendingChange: boolean;
        ensureNodeVisible: boolean;
      } = { pendingChange: false, ensureNodeVisible: true },
    ) => {
      if (gridRef.current) {
        const node = options.node ?? (gridRef.current.api.getRowNode(_selectedListingId) as RowNode<BarkerEventListing>);
        if (node) {
          if (!node.isSelected()) {
            node.setSelected(true);
            setSelectedListingId(node.data?.tenantIdListingId);
          }
          if (!node.isExpandable()) {
            // @ts-ignore
            gridRef.current?.api.__getAlignedGridService().gridOptionsService.set('alignedGrids', []);
          }
          if (options.ensureNodeVisible) {
            requestAnimationFrame(() => {
              gridRef.current?.api.ensureNodeVisible(node);
            });
          }
        } else if (!options.pendingChange) {
          const firstNode = gridRef.current?.api.getRenderedNodes()[1] as RowNode<BarkerEventListing>;
          if (firstNode) {
            firstNode.setSelected(true);
            setSelectedListingId(firstNode.data?.tenantIdListingId);
          }
        }
      }
    },
    [setSelectedListingId],
  );

  const { data: soldInventory } = useGetApiSales(
    {
      eventId: selectedEvent?.eventId ?? '',
      fromDate: dayjs().subtract(2, 'year').startOf('day').toDate(),
      toDate: dayjs().endOf('day').endOf('day').toDate(),
    },
    {
      query: {
        enabled: !!selectedEvent?.eventId && isShowingEventPerformance,
        select(data) {
          return data.data.sales.flatMap((sale) => sale.listings.map((listing) => ({ ...listing, event: selectedEvent })));
        },
        queryKey: [
          ...getGetApiSalesQueryKey({
            eventId: selectedEvent?.eventId ?? '',
            fromDate: dayjs().subtract(2, 'year').startOf('day').toDate(),
            toDate: dayjs().endOf('day').endOf('day').toDate(),
          }),
          selectedEvent?.tenantId,
        ],
      },
      axios: {
        headers: {
          'x-tenant-id': selectedEvent?.tenantId,
        },
      },
    },
  );

  useDidUpdate(() => {
    if (isShowingEventPerformance && soldInventory && soldInventory.length > 0) {
      const node = gridRef.current?.api.getRowNode(`${selectedEvent?.tenantId}|${selectedEvent?.eventId}|sales`) as RowNode<BarkerEventListing>;
      if (node) {
        node.setExpanded(true);
      }
    } else if (!isShowingEventPerformance && soldInventory && soldInventory.length > 0) {
      const node = gridRef.current?.api.getRowNode(`${selectedEvent?.tenantId}|${selectedEvent?.eventId}|sales`) as RowNode<BarkerEventListing>;
      if (node) {
        node.setExpanded(false);
      }
    }
  }, [soldInventory, selectedEvent, isShowingEventPerformance]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (transientGlobalState.expandGroupByListingId) {
        const node = gridRef.current?.api.getRowNode(transientGlobalState.expandGroupByListingId) as RowNode<BarkerEventListing>;
        if (node) {
          if (!node.id?.includes('sales')) {
            node.setSelected(true);
            node.setExpanded(true);
          }
          setTransientGlobalState((s) => ({ ...s, expandGroupByListingId: null }));
          clearTimeout(timer);
        }
      }
    }, 250);

    return () => clearTimeout(timer);
  }, [setTransientGlobalState, transientGlobalState.expandGroupByListingId]);

  const onRowDataUpdated = useCallback(
    (event: RowDataUpdatedEvent<BarkerEventListing>) => {
      if (!selectedListingId.hasValue() && !selectedEvent) {
        const firstNode = event.api.getRenderedNodes()[1];
        if (firstNode && firstNode.data && !isShowingEventPerformance) {
          setSelectedListingId(firstNode.data.tenantIdListingId);
        }
      } else if (selectedListingId.hasValue() && event.api.getRowNode(selectedListingId.toString())) {
        const node = event.api.getRowNode(selectedListingId.toString()) as RowNode<BarkerEventListing>;
        if (node) {
          if (!node.isSelected()) {
            node.setSelected(true);
            event.api.ensureNodeVisible(node);
            setSelectedListingId(node.data?.tenantIdListingId);
          }
          if (node.isExpandable()) {
            node.setExpanded(true);
          }
        }
      } else if (!selectedEvent) {
        const firstNode = event.api.getRenderedNodes()[1];
        if (firstNode && firstNode.data && !isShowingEventPerformance) {
          setSelectedListingId(firstNode.data.tenantIdListingId);
        }
      } else if (selectedListingId.hasValue()) {
        const node = event.api.getRowNode(selectedListingId.toString()) as RowNode<BarkerEventListing>;
        if (!node) {
          const firstNode = event.api.getRenderedNodes()[1];
          if (firstNode && firstNode.data && !isShowingEventPerformance) {
            setSelectedListingId(firstNode.data.tenantIdListingId);
          }
        }
      }
    },
    [isShowingEventPerformance, selectedEvent, selectedListingId, setSelectedListingId],
  );

  const isRowDragging = useAtomValue(rowDraggingAtom);

  useDidUpdate(() => {
    if (gridRef.current && gridRef.current.api && !isRowDragging) {
      if (selectedListingId.hasValue() && !transientGlobalState.ignoreGridScrolling) {
        gridNodeSelection(selectedListingId.toString(), { pendingChange: true, ensureNodeVisible: true });
      }
    }
  }, [mergedEventListings]);

  const enableCellTextSelection = useFlag('grid-text-selection');

  // Details grid drag hovers

  const detailCellRendererParams = () =>
    ({
      refreshStrategy: 'everything',
      detailGridOptions: {
        getRowId: (x) => x.data.tenantIdListingId,
        alignedGrids: gridRef.current ? [gridRef.current] : undefined,
        rowSelection: 'single',
        enableRangeSelection: false,
        columnDefs: columnDefs
          .filter((x) => x.field !== 'tenantIdEventId')
          .map((x) => ({
            ...x,
            sort: null,
            sortIndex: null,
          })),
        headerHeight: -1,
        rowBuffer: 0,
        animateRows: false,
        suppressContextMenu: true,
        suppressColumnMoveAnimation: true,
        suppressRowClickSelection: true,
        suppressHorizontalScroll: true,
        enableCellChangeFlash: true,
        enableCellTextSelection,
        alwaysShowVerticalScroll: false,
        rowHeight: 36,
        rowStyle: { lineHeight: '28px', fontSize: 12 },
        onGridReady: detailGridReady,
        onRowDragEnd: onDetailRowDragEnd,
        onRowDragMove: onMasterRowDragMove,
        onGridPreDestroyed: () => {
          const grids: DetailGridInfo[] = [];
          gridRef.current?.api.forEachDetailGridInfo((grid) => {
            grids.push(grid);
          });
          // @ts-ignore
          gridRef.current?.api.__getAlignedGridService().gridOptionsService.set('alignedGrids', grids);
        },
        rowClassRules: {
          'active-row': (cssParams) => {
            if (cssParams.data) {
              if (cssParams.data.pendingBroadcast !== undefined) {
                if (cssParams.data.pendingBroadcast) {
                  return true;
                }
              } else if (cssParams.data.isBroadcasting) {
                return true;
              }
            }
            return false;
          },
          'ag-row-listing-at-floor': (cssParams) => {
            if (appearanceSettings && appearanceSettings.highlightListingsAtFloor && cssParams.data && cssParams.data.pricerStatusId === 'AtFloor') {
              return true;
            }
            return false;
          },
          'ag-row-listing-on-hold': (cssParams) => {
            if (appearanceSettings && appearanceSettings.highlightListingsOnHold && cssParams.data && cssParams.data.quantityReserved > 0) {
              return true;
            }
            return false;
          },
        },
        defaultColDef: {
          sortable: false,
          suppressMenu: true,
          resizable: false,
          flex: 1,
        },
        suppressCellFocus: true,
        rowDragText: (dragParams) => {
          const sectionName = dragParams.rowNode?.data.section;
          const rowName = dragParams.rowNode?.data.row;
          const seatsFrom = dragParams.rowNode?.data.seatFrom;
          const seatsTo = dragParams.rowNode?.data.seatThru;
          return `Section ${sectionName} · Row ${rowName} · Seats ${seatsFrom}-${seatsTo}`;
        },
      },
      getDetailRowData,
    }) satisfies Partial<IDetailCellRendererParams<BarkerEventListingWithPending, BarkerEventListingWithPending>>;

  const getSoldRowData = (params: GetDetailRowDataParams) => {
    if (soldInventory && soldInventory.length > 0 && selectedEvent) {
      const remappedSoldInventory = soldInventory
        .filter((x) => x.sale)
        .map((x) => ({
          ...x,
          tenantIdListingId: `${x.tenantId}|${x.listingId}|${x.sale.saleId}`,
          quantityRemaining: x.quantity, // Remapped to make columnDefs consistent
        }))
        .sort((a, b) => (a.sale.createdAt < b.sale.createdAt ? 1 : -1));
      params.successCallback(remappedSoldInventory);
    }
  };

  const soldInventoryDetailCellRendererParams = () =>
    ({
      refreshStrategy: 'everything',
      detailGridOptions: {
        getRowId: (x) => x.data.tenantIdListingId,
        alignedGrids: gridRef.current ? [gridRef.current] : undefined,
        rowSelection: 'single',
        enableRangeSelection: false,
        columnDefs: columnDefs
          .filter((x) => x.field !== 'tenantIdEventId')
          .map((x) => ({
            ...x,
            sort: null,
            sortIndex: null,
          })),
        headerHeight: -1,
        rowBuffer: 0,
        animateRows: false,
        suppressColumnMoveAnimation: true,
        onGridReady: soldInventoryGridReady,
        onGridPreDestroyed: () => {
          const grids: DetailGridInfo[] = [];
          gridRef.current?.api.forEachDetailGridInfo((grid) => {
            grids.push(grid);
          });
          // @ts-ignore
          gridRef.current?.api.__getAlignedGridService().gridOptionsService.set('alignedGrids', grids);
        },
        suppressRowClickSelection: true,
        suppressHorizontalScroll: true,
        enableCellChangeFlash: true,
        suppressContextMenu: true,
        noRowsOverlayComponent: () => <BNEmptyState title="No Sold Inventory" titleFz={12} border={false} />,
        rowHeight: 36,
        rowStyle: { lineHeight: '28px', fontSize: 12 },
        rowClass: 'sales-row',
        defaultColDef: {
          sortable: false,
          suppressMenu: true,
          resizable: false,
          flex: 1,
        },
        suppressCellFocus: true,
        masterDetail: true,
        detailCellRenderer: SaleDetails,
        detailRowHeight: 30,
        groupDefaultExpanded: 1,
        embedFullWidthRows: true,
        // This keeps the rows in order to enable proper hover states,
        // but may need future attention because it keeps remnants of the rows
        // and forces extra space even after something else is selected
        // keepDetailRows: true,
        isRowMaster: () => true,
        onCellClicked: (e) => {
          if (e.column.getColId() === 'dragCell') {
            // Toggle ONE open or closed
            // return e.node.setExpanded(!e.node.expanded);

            // Toggle ALL open or closed
            return e.api.forEachNode((node) => {
              node.setExpanded(!node.expanded);
            });
          }
          return false;
        },
      },
      getDetailRowData: getSoldRowData,
    }) satisfies Partial<IDetailCellRendererParams<BarkerEventListingWithPending, BarkerEventListingWithPending>>;

  const trySaveGridState = useCallback(
    (event: ColumnEvent) => {
      if (gridReady) {
        const state = event.columnApi?.getColumnState()!;
        setInventoryGridState(state);
        if (event.type === 'columnMoved') {
          document.dispatchEvent(onColumnMoved);
        }
        if (event.type === 'sortChanged') {
          document.dispatchEvent(onColumnSorted);
        }
      }
    },
    [gridReady, setInventoryGridState],
  );

  const moveNextSelectedRow = useCallback(async () => {
    const index = gridRef.current?.api.getSelectedNodes()[0]?.rowIndex ?? 0;
    const topLevelRows = gridRef.current?.api.getRenderedNodes().filter((x) => x.uiLevel === 1 && !x.detail && !x.data?.listingId.includes('sales') && x.rowIndex! > index);
    const node = topLevelRows?.[0] as RowNode<BarkerEventListing>;
    if (node && node.data && node.data.tenantIdListingId !== selectedListingId.toString()) {
      const { tenantIdListingId } = node.data;
      if (await validateDirtyRuleState()) {
        await triggerSaveRule();
        setSelectedListingId(tenantIdListingId);
        gridNodeSelection(tenantIdListingId, { node, ensureNodeVisible: false, pendingChange: false });
        requestAnimationFrame(() => {
          gridRef.current?.api.ensureNodeVisible(node);
        });
      }
    }
  }, [gridNodeSelection, selectedListingId, setSelectedListingId, triggerSaveRule, validateDirtyRuleState]);

  const movePreviousSelectedRow = useCallback(async () => {
    const index = gridRef.current?.api.getSelectedNodes()[0]?.rowIndex ?? 0;
    const topLevelRows = gridRef.current?.api
      .getRenderedNodes()
      .filter((x) => x.uiLevel === 1 && !x.detail && !x.data?.listingId.includes('sales') && x.rowIndex! < index)
      .sort((a, b) => b.rowIndex! - a.rowIndex!);
    const node = topLevelRows?.[0] as RowNode<BarkerEventListing>;
    if (!node?.data || node.data.tenantIdListingId === selectedListingId.toString()) {
      return;
    }
    if (await validateDirtyRuleState()) {
      const { tenantIdListingId } = node.data;
      await triggerSaveRule();
      setSelectedListingId(tenantIdListingId);
      gridNodeSelection(tenantIdListingId, { node, ensureNodeVisible: false, pendingChange: false });
      requestAnimationFrame(() => {
        gridRef.current?.api.ensureNodeVisible(node);
      });
    }
  }, [gridNodeSelection, selectedListingId, setSelectedListingId, triggerSaveRule, validateDirtyRuleState]);

  useEffect(() => {
    document.addEventListener(onNextInventoryItem.type, moveNextSelectedRow);
    document.addEventListener(onPrevInventoryItem.type, movePreviousSelectedRow);

    return () => {
      document.removeEventListener(onNextInventoryItem.type, moveNextSelectedRow);
      document.removeEventListener(onPrevInventoryItem.type, movePreviousSelectedRow);
    };
  }, [moveNextSelectedRow, movePreviousSelectedRow]);

  return {
    groupSortOrderComparator,
    gridRef,
    mergedEventListings: filteredListings,
    columnDefs,
    onGridReady,
    onMasterRowDragEnd,
    onMasterRowDragMove,
    detailCellRendererParams,
    defaultColDef,
    selectedHistoryListing,
    setSelectedHistoryListing,
    toggleShowSort,
    onCellClickedWhenDirty,
    setInventoryQuickFilter,
    setFilter,
    filter,
    inventoryQuickFilter,
    gridReady,
    clearInventoryGridState,
    trySaveGridState,
    updateEventSort,
    eventGridState,
    inventoryGridState,
    clearEventGridState,
    defaultColumnDefs,
    showBulkOptions,
    toggleBulkOptions,
    soldInventoryDetailCellRendererParams,
    isShowingEventPerformance,
    getDetailRowDataResults,
    onRowGroupToggled,
    onRowDataUpdated,
    showPurchaseModal,
    openPurchaseModal,
    closePurchaseModal,
    setInitialPurchaseEvent,
    initialPurchaseEvent,
    updateBroadcasting,
  };
};

export const { Provider: InventoryStateProvider, useSelector: useInventory } = yasml(InventoryState);
