import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Text } from '@mantine/core';
import { openConfirmModal } from '@mantine/modals';
import {
  BarkerCoreEnumsPricerStatus,
  BarkerCoreModelsPricingRule,
  BarkerCoreModelsPricingRuleBase,
  getApiPricingRules,
  getGetApiPricingRulesRuleIdQueryKey,
  useGetApiPricingRulesRuleId,
  usePostApiPricingRules,
  usePutApiPricingRulesRuleId,
} from '../api';
import { BarkerCoreModelsInventoryListingExtended, Rule } from '../types';
import {
  applyRuleToSelectedGroupedListingsAtom,
  filtersDirtyAtom,
  forceRuleIdAtom,
  initiallyAutoPricedAtom,
  ruleDirtyAtom,
  ruleStateAtom,
  ruleStateInitialValueAtom,
  ruleStateResetAtom,
  searchResultsAtom,
  selectedMarketplaceEventAtom,
  selectedMergedListingAtom,
  targetComparablesAtom,
  transientGlobalStateAtom,
  updateListingsAtom,
} from './atoms';
import { useGlobalState } from './GlobalState';
import yasml from '@thirtytech/yasml';
import { useDidUpdate } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { useDelayedValue } from '../utils/use-delayed-value';
import ErrorIcon from '../components/icons/Error';
import { useForm } from '@mantine/form';
import { useLocation } from 'react-router-dom';
import { useDidUpdateDeep, useMemoDeep } from '../utils/use-memo-weak';
import { deepEqual } from 'fast-equals';
import { useDebounce } from 'rooks';
import { queryClient } from './api-config';
import { useListingState } from './ListingState';
import { useQueryParam } from '../utils/use-query-param';
import { v4 as uuid } from 'uuid';
import { useFlag } from '@unleash/proxy-client-react';
import { ruleSubscriptionFlagAtom } from './atom.subscriptions';
import { useMessageHandlers } from './GlobalState.messages';

const RuleState = () => {
  const referenceId = useQueryParam('referenceId', '');
  const isRuleRoute = useLocation().pathname.includes('/rule');
  const setTransientGlobalState = useSetAtom(transientGlobalStateAtom);
  const ruleDirty = useAtomValue(ruleDirtyAtom);
  const filtersDirty = useAtomValue(filtersDirtyAtom);
  const selectedListing = useAtomValue(selectedMergedListingAtom);
  const updateInventoryListing = useSetAtom(updateListingsAtom);
  const applyRuleToGroupedListings = useSetAtom(applyRuleToSelectedGroupedListingsAtom);
  const targetComparable = useAtomValue(targetComparablesAtom);
  const [rule, setRuleState] = useAtom(ruleStateAtom);
  const initiallyAutoPriced = useAtomValue(initiallyAutoPricedAtom);
  const { currentUser, isTenantsLoading } = useGlobalState();
  useMessageHandlers({ isLoading: isTenantsLoading });
  const initialRuleState = useAtomValue(ruleStateInitialValueAtom);
  const location = useLocation();
  const isSeasonView = location.pathname === '/seasons';
  const {
    pendingListingUpdates,
    pendingListingUpdatesByProperty,
    processPendingUpdates,
    setPendingListingUpdates,
    splitListingRule,
    rejectPendingUpdates,
    rejectPendingListingUpdates,
  } = useListingState(
    'pendingListingUpdates',
    'pendingListingUpdatesByProperty',
    'processPendingUpdates',
    'setPendingListingUpdates',
    'splitListingRule',
    'rejectPendingUpdates',
    'rejectPendingListingUpdates',
  );
  const {
    mutateAsync: createRule,
    isPending: createRuleLoading,
    isError: isCreateRuleError,
    error: createRuleError,
  } = usePostApiPricingRules({
    axios: {
      headers: {
        'x-tenant-id': selectedListing?.tenantId,
      },
    },
  });
  const {
    mutateAsync: putRule,
    isPending: putRuleLoading,
    isError: isPutRuleError,
    error: putRuleError,
  } = usePutApiPricingRulesRuleId({
    axios: {
      headers: {
        'x-tenant-id': selectedListing?.tenantId,
      },
    },
  });
  // TODO: Need to deal with this setting the isLocalRule when in isRuleRoute
  const [forceRuleId, setForceRuleId] = useAtom(forceRuleIdAtom);
  const { data: serverRule, isFetching: ruleLoading } = useGetApiPricingRulesRuleId(selectedListing?.ruleId! ?? forceRuleId, {
    // Removing the ruleId on an off the selected listing causes a snowball effect for seating chart
    query: {
      enabled: !!forceRuleId || (!!selectedListing?.ruleId && !selectedListing?.meta?.isLocalRule && !rule.meta?.isLocalRule),
      select(data) {
        return data.data;
      },
    },
    axios: {
      headers: {
        'x-tenant-id': selectedListing?.tenantId,
      },
    },
  });

  const ruleForm = useForm<Pick<Rule['filters'], 'rows' | 'sectionIds' | 'splits' | 'exclusions' | 'deliveryExclusions' | 'outlierCriteria'> & Pick<Rule, 'autoAdjustSplits'>>({
    initialValues: {
      rows: rule.filters.rows,
      sectionIds: rule.filters.sectionIds || [],
      autoAdjustSplits: rule.autoAdjustSplits,
      splits: rule.filters.splits,
      deliveryExclusions: rule.filters.deliveryExclusions,
      outlierCriteria: rule.filters.outlierCriteria,
      exclusions: rule.filters.exclusions,
    },
  });

  const formValues = useMemoDeep(() => ruleForm.values, [ruleForm.values]);
  const form = useMemoDeep<typeof ruleForm>(
    () => ({
      ...ruleForm,
      values: formValues,
    }),
    [formValues],
  );
  const resetFormDirtyState = useCallback(() => {
    form.setValues({ ...initialRuleState.filters, autoAdjustSplits: initialRuleState.autoAdjustSplits });
    form.resetDirty({ ...initialRuleState.filters, autoAdjustSplits: initialRuleState.autoAdjustSplits });
  }, [form, initialRuleState.autoAdjustSplits, initialRuleState.filters]);

  const [formKey, setFormKey] = useState(0);
  const resetForm = useCallback(
    (skipDirtyReset = false) => {
      if (!skipDirtyReset) {
        resetFormDirtyState();
      }
      setFormKey((prev) => prev + 1);
    },
    [resetFormDirtyState],
  );

  const submit = form.onSubmit((values) => {
    const { autoAdjustSplits, ...rest } = values;
    const newRule: Rule = { ...rule, autoAdjustSplits, filters: { ...rule.filters, ...rest } };
    setRuleState(newRule);
    form.setValues({ ...rest, autoAdjustSplits });
    form.resetDirty({ ...rest, autoAdjustSplits });
  });

  useDidUpdate(() => {
    resetFormDirtyState();
  }, [rule.ruleId]);

  const [previousRuleId, setPreviousRuleId] = useState(rule.ruleId);
  useDidUpdate(() => {
    if (previousRuleId !== rule.ruleId) {
      setPreviousRuleId(rule.ruleId);
    }
  }, [rule.ruleId]);

  const setRuleStateDebounced = useDebounce(setRuleState, 50);
  useDidUpdateDeep(() => {
    if (!isSeasonView) {
      if (previousRuleId === rule.ruleId && !ruleLoading) {
        const { autoAdjustSplits, ...rest } = formValues;
        const newRule = { ...rule, autoAdjustSplits, filters: { ...rule.filters, ...rest } };
        if (!deepEqual(newRule, rule)) {
          setRuleStateDebounced(newRule);
        }
      }
    }
  }, [formValues]);

  useDidUpdateDeep(() => {
    form.setValues({
      rows: rule.filters.rows,
      sectionIds: rule.filters.sectionIds,
      autoAdjustSplits: rule.autoAdjustSplits,
      splits: rule.filters.splits,
      deliveryExclusions: rule.filters.deliveryExclusions,
      exclusions: rule.filters.exclusions,
      outlierCriteria: rule.filters.outlierCriteria,
    });
  }, [
    rule.filters.deliveryExclusions,
    rule.filters.exclusions,
    rule.filters.outlierCriteria,
    rule.filters.rows,
    rule.filters.sectionIds,
    rule.filters.splits,
    rule.autoAdjustSplits,
  ]);

  const enableRuleSubscription = useFlag('enable-rule-subscription');
  const setRuleSubscriptionFlag = useSetAtom(ruleSubscriptionFlagAtom);
  useEffect(() => {
    setRuleSubscriptionFlag(enableRuleSubscription);
  }, [enableRuleSubscription, setRuleSubscriptionFlag]);

  useDidUpdate(() => {
    if (!enableRuleSubscription) {
      if (serverRule && serverRule.ruleId !== '00000000-0000-0000-0000-000000000000') {
        if (!selectedListing?.ruleId && forceRuleId) {
          // Note: This puts the rule template into a more default state. Server actions will update the rule
          delete serverRule.warningNotes;
          delete serverRule.pausedAt;
          delete serverRule.pausedBy;
          delete serverRule.errorMessage;
          delete serverRule.checkedAt;
          serverRule.isError = false;
          serverRule.isAutoPriced = false;
          setRuleState(serverRule);
          setTransientGlobalState((s) => ({ ...s, autoUpdateAutoPricedListPriceRuleId: serverRule.ruleId }));
        } else {
          setRuleState(serverRule);
        }
      } else if (selectedListing && !selectedListing?.ruleId && !selectedListing?.meta?.isLocalRule) {
        setRuleState(null);
      }
    }
  }, [serverRule, enableRuleSubscription]);

  const isLocalRule = useMemo(() => (!serverRule || serverRule.ruleId === '00000000-0000-0000-0000-000000000000') && !rule.updatedAt, [serverRule, rule]);
  const searchResults = useAtomValue(searchResultsAtom);
  const listingsByRuleId = useMemo(() => searchResults?.listings.filter((x) => x.ruleId === rule?.ruleId).sort((a, b) => a.ruleTier! - b.ruleTier!), [searchResults, rule?.ruleId]);
  const resetRuleState = useSetAtom(ruleStateResetAtom);

  const validateDirtyRuleState = useCallback(async () => {
    if (
      (rule.isAutoPriced || rule.automationTypeId === 'SchedulePrice' || initiallyAutoPriced) &&
      (ruleDirty || filtersDirty || pendingListingUpdates.filter((x) => listingsByRuleId?.map((y) => y.listingId)?.includes(x.listingId)).length > 0)
    ) {
      return new Promise<boolean>((resolve) => {
        openConfirmModal({
          title: 'Unsaved Changes',
          children: <Text size="sm">Changes to this listing will be reset if you continue.</Text>,
          labels: { confirm: 'Reset & Continue', cancel: 'Cancel' },
          confirmProps: { className: 'confirmButton', variant: 'default', size: 'sm' },
          cancelProps: { className: 'cancelButton', variant: 'filled', color: 'gray', size: 'sm' },
          closeButtonProps: { size: 'md' },
          onClose() {
            resolve(false);
          },
          onConfirm() {
            rejectPendingUpdates();
            resetRuleState();
            resetForm();
            resolve(true);
          },
          onCancel() {
            resolve(false);
          },
        });
      });
    }
    return Promise.resolve(true);
  }, [
    rule.isAutoPriced,
    rule.automationTypeId,
    initiallyAutoPriced,
    ruleDirty,
    filtersDirty,
    pendingListingUpdates,
    listingsByRuleId,
    rejectPendingUpdates,
    resetRuleState,
    resetForm,
  ]);

  const calculatePricerStatusId = useCallback(
    (_rule: BarkerCoreModelsPricingRule | Rule, listing: BarkerCoreModelsInventoryListingExtended): BarkerCoreEnumsPricerStatus => {
      const price = (pendingListingUpdates.find((x) => x.listingId === listing.listingId && x.property === 'unitPrice')?.value || listing.unitPrice) as number;
      const tier = listingsByRuleId?.indexOf(listing) ?? -1;

      if (_rule.pausedAt) {
        if (_rule.warningNotes) {
          return 'PausedWithWarning';
        }

        return 'Paused';
      }

      if (_rule.isAutoPriced) {
        if (targetComparable === null) {
          return 'NoComparables';
        }
        if (_rule.floorPrice && price <= _rule.floorPrice) {
          return 'AtFloor';
        }
        if (_rule.ceilingPrice && price >= _rule.ceilingPrice && tier === 0) {
          return 'AtCeiling';
        }
        return 'AutoPriced';
      }

      if (rule.automationTypeId === 'SchedulePrice') {
        if (_rule.floorPrice && price <= _rule.floorPrice) {
          return 'AtFloor';
        }
        if (_rule.ceilingPrice && price >= _rule.ceilingPrice && tier === 0) {
          return 'AtCeiling';
        }
        return 'Scheduled';
      }
      return 'None';
    },
    [pendingListingUpdates, listingsByRuleId, targetComparable, rule.automationTypeId],
  );

  useEffect(() => {
    if (isCreateRuleError || isPutRuleError) {
      let responseData = createRuleError?.response?.data ?? putRuleError?.response?.data;
      if (typeof responseData === 'object') {
        responseData = (responseData as any).title;
      }
      notifications.show({
        title: 'Rule save error.',
        message: `${responseData}. If this problem persists please contact support.`,
        icon: <ErrorIcon size={24} />,
        color: 'red',
      });
    }
  }, [createRuleError, isCreateRuleError, isPutRuleError, putRuleError]);

  // NOTE: Ref is used because quick auto saving, like mobile navigation, can cause a double save which throws on the POST vs PUT
  const _isRuleSaving = useRef(false);
  const saveRuleTemplate = useCallback(
    async (_rule: Omit<BarkerCoreModelsPricingRuleBase, 'tenantId'>) => {
      const _ruleUpdate = { ..._rule };

      const ruleResponse = await getApiPricingRules(
        {
          referenceId,
        },
        { headers: { 'x-tenant-id': selectedListing?.tenantId } },
      );

      if (!ruleResponse.data?.ruleId) {
        _ruleUpdate.referenceId = referenceId;
        _ruleUpdate.ruleId = uuid();
        await createRule({ data: { ..._ruleUpdate } });
      } else if (_rule.ruleId) {
        _ruleUpdate.ruleId = ruleResponse.data.ruleId;
        _ruleUpdate.referenceId = referenceId;
        await putRule({ ruleId: ruleResponse.data.ruleId, data: _ruleUpdate });
      }
    },
    [createRule, putRule, referenceId, selectedListing?.tenantId],
  );
  const saveRule = useCallback(
    async (ruleUpdate: BarkerCoreModelsPricingRule | Omit<BarkerCoreModelsPricingRuleBase, 'tenantId'>) => {
      try {
        if (_isRuleSaving.current) {
          return null;
        }
        _isRuleSaving.current = true;
        let pendingRuleUpdate = ruleUpdate;
        if (!ruleUpdate.isAutoPriced && rule.automationTypeId && ruleUpdate.automationTypeId !== 'SchedulePrice') {
          pendingRuleUpdate = {
            ...pendingRuleUpdate,
            adjustmentTypeId: 'Amount',
            adjustmentValue: undefined,
            ceilingPrice: undefined,
            floorPrice: undefined,
            isAutoPriced: false,
            numActive: undefined,
            staggerByTypeId: 'Amount',
            staggerByValue: undefined,
          };
        }

        // Precaution to prevent accidental rule creation of a bad ruleId
        if (pendingRuleUpdate.ruleId === '00000000-0000-0000-0000-000000000000') {
          _isRuleSaving.current = false;
          throw new Error('Rule ID is required');
        }

        let isRuleIdSwapCondition = false;
        if (isRuleRoute && rule.ruleId === forceRuleId && selectedListing && !pendingRuleUpdate.isAutoPriced) {
          return null;
        }

        if (isRuleRoute && rule.ruleId === forceRuleId && selectedListing) {
          pendingRuleUpdate.ruleId = uuid();
          (pendingRuleUpdate as BarkerCoreModelsPricingRule).referenceId = null;
          isRuleIdSwapCondition = true;
        } else if (isRuleRoute && !selectedListing) {
          (pendingRuleUpdate as BarkerCoreModelsPricingRule).referenceId = referenceId;
          // pendingRuleUpdate.isAutoPriced = true;
        }

        const savedRule =
          isRuleIdSwapCondition || isLocalRule
            ? await createRule({ data: { ...pendingRuleUpdate } })
            : await putRule({ ruleId: (pendingRuleUpdate as BarkerCoreModelsPricingRule).ruleId, data: { ...(pendingRuleUpdate as BarkerCoreModelsPricingRule) } });

        listingsByRuleId?.forEach((listing) => {
          const pricerStatusId = calculatePricerStatusId(savedRule.data, listing);
          if (
            listing?.pricerStatusId !== pricerStatusId ||
            pendingListingUpdates.find((x) => x.tenantId === listing.tenantId && x.listingId === listing.listingId && x.property === 'unitPrice')
          ) {
            pendingListingUpdates.push({
              tenantId: listing.tenantId,
              listingId: listing.listingId,
              property: 'pricerStatusId',
              value: pricerStatusId,
              previousValue: listing.pricerStatusId,
            });
          }

          // eslint-disable-next-line no-param-reassign
          listing.pricerStatusId = pricerStatusId;

          // Cosmetic UI updates for columns based off the rule floor and ceiling
          if (listing.floorPrice !== rule.floorPrice) {
            // eslint-disable-next-line no-param-reassign
            listing.floorPrice = rule.floorPrice;
          }
          if (listing.ceilingPrice !== rule.ceilingPrice) {
            // eslint-disable-next-line no-param-reassign
            listing.floorPrice = rule.floorPrice;
          }
        });

        if (selectedListing) {
          if (isLocalRule || (isRuleIdSwapCondition && pendingRuleUpdate.isAutoPriced)) {
            pendingListingUpdates.push({
              tenantId: selectedListing.tenantId,
              listingId: selectedListing.listingId,
              property: 'ruleId',
              value: savedRule.data.ruleId,
              previousValue: selectedListing.ruleId,
            });
          }
          // Temp fix for Winventory broadcasting. Need to streamline this
          if (isRuleIdSwapCondition && pendingRuleUpdate.isAutoPriced && !selectedListing.isBroadcasting) {
            pendingListingUpdates.push({
              tenantId: selectedListing.tenantId,
              listingId: selectedListing.listingId,
              property: 'isBroadcasting',
              value: true,
              previousValue: selectedListing.isBroadcasting,
            });
          }
          if (
            listingsByRuleId?.length === 1 &&
            !ruleUpdate.isAutoPriced &&
            ruleUpdate.automationTypeId !== 'SchedulePrice' &&
            selectedListing.pricerStatusId &&
            selectedListing.pricerStatusId !== 'None'
          ) {
            pendingListingUpdates.push({
              tenantId: selectedListing.tenantId,
              listingId: selectedListing.listingId,
              property: 'pricerStatusId',
              value: 'None',
              previousValue: selectedListing.pricerStatusId,
            });
          }

          const filteredPendingUpdates = pendingListingUpdates.filter((x) => !x.inlinePriceChange);
          await processPendingUpdates(filteredPendingUpdates);
          setRuleState(savedRule.data, { forceUpdate: true, currentListingId: selectedListing.tenantIdListingId });
        }
        if (forceRuleId) {
          setForceRuleId(null);
        }
        queryClient.setQueryData(getGetApiPricingRulesRuleIdQueryKey(savedRule.data.ruleId), savedRule);
        if (isRuleRoute) {
          if (!selectedListing) {
            setForceRuleId(savedRule.data.ruleId);
          }
          postMessage({ type: 'bn.ruleSaved', data: { ruleId: savedRule.data.ruleId, eventId: savedRule.data.pointOfSaleEventId, listingId: selectedListing?.listingId } });
        }

        _isRuleSaving.current = false;
        return savedRule.data;
      } catch (error) {
        _isRuleSaving.current = false;
        throw error;
      }
    },
    [
      calculatePricerStatusId,
      createRule,
      forceRuleId,
      isLocalRule,
      isRuleRoute,
      listingsByRuleId,
      pendingListingUpdates,
      processPendingUpdates,
      putRule,
      rule.automationTypeId,
      rule.ceilingPrice,
      rule.floorPrice,
      rule.ruleId,
      selectedListing,
      setForceRuleId,
      setRuleState,
      referenceId,
    ],
  );

  const disbandRule = useCallback(async () => {
    const pendingRuleIdListings = pendingListingUpdates.filter((x) => x.property === 'ruleId');
    const listingsToDisband = listingsByRuleId?.filter((x) => !pendingRuleIdListings.find((y) => y.listingId === x.listingId));
    if (!isLocalRule && listingsToDisband && listingsToDisband?.length > 1) {
      const result = await splitListingRule({
        ruleId: rule.ruleId!,
        data: {
          listingIds: listingsToDisband.map((x) => x.listingId),
        },
      });

      const listingsToUpdate = listingsByRuleId?.map((listing) => {
        const updatedListing = { ...listing };
        updatedListing.ruleId = result.data.find((x) => x.listingId === updatedListing.listingId)?.ruleId;
        updatedListing.pricerStatusId = 'None';
        updatedListing.ruleTier = null;
        updatedListing.floorPrice = null;
        updatedListing.ceilingPrice = null;
        return updatedListing;
      });
      if (listingsToUpdate) {
        updateInventoryListing(listingsToUpdate);
      }
    }
    if (isLocalRule) {
      // Reset local rule
      setRuleState(null);
    }
    rejectPendingListingUpdates();

    // Hack: Needed to update drag cell handle when on selected listing when disbanding a group. Need a better way
    const anchorNode = (window as any).gridRef?.api.getRowNode(selectedListing?.tenantIdListingId);
    setTimeout(() => {
      (window as any).gridRef?.api.redrawRows({ rowNodes: [anchorNode] });
    }, 250);
  }, [pendingListingUpdates, listingsByRuleId, isLocalRule, rejectPendingListingUpdates, selectedListing, splitListingRule, rule.ruleId, updateInventoryListing, setRuleState]);

  const selectedMarketplaceEvent = useAtomValue(selectedMarketplaceEventAtom);
  const triggerSaveRule = useCallback(async () => {
    // Extra sanity check that the rule is not auto priced for this auto save rule filters function
    if (
      !referenceId &&
      currentUser &&
      currentUser.roleId !== 'ReadOnlyUser' &&
      selectedListing &&
      selectedMarketplaceEvent &&
      selectedMarketplaceEvent.marketplaceId &&
      !createRuleLoading &&
      !putRuleLoading && //Extra checks to not auto save a rule that is saving
      filtersDirty &&
      !rule.isAutoPriced
      // && selectedListing.quantityReserved === 0 // LWW 1/27/25 - Not sure we need this. Who cares if a listing is reserved to determine if we can save criteria for it?
    ) {
      await saveRule({
        ...rule,
        tenantId: currentUser.tenantId,
        pointOfSaleEventId: selectedListing.eventId,
        marketplaceId: selectedMarketplaceEvent.marketplaceId,
      });
    }
  }, [createRuleLoading, currentUser, filtersDirty, putRuleLoading, referenceId, rule, saveRule, selectedListing, selectedMarketplaceEvent]);

  const addCurrentRuleToListing = useCallback(() => {
    if (selectedListing && !selectedListing?.ruleId) {
      const ruleId = rule!.ruleId!;
      setPendingListingUpdates((prev) => [
        ...prev,
        {
          listingId: selectedListing.listingId,
          tenantId: selectedListing.tenantId,
          property: 'ruleId',
          value: ruleId,
          previousValue: selectedListing.ruleId,
        },
      ]);
      updateInventoryListing({ ...selectedListing, ruleId, meta: { isLocalRule: true } });
    }
  }, [rule, selectedListing, setPendingListingUpdates, updateInventoryListing]);

  useEffect(() => {
    const handleTabClose = async (e: BeforeUnloadEvent) => {
      if (ruleDirty || filtersDirty || pendingListingUpdates.length > 0) {
        await triggerSaveRule();
        e.preventDefault();
        e.returnValue = '';
        return '';
      }
      return null;
    };
    window.addEventListener('beforeunload', handleTabClose);
    return () => {
      window.removeEventListener('beforeunload', handleTabClose);
    };
  }, [filtersDirty, pendingListingUpdates.length, ruleDirty, triggerSaveRule]);

  const isLoading = useMemo(() => createRuleLoading || putRuleLoading || ruleLoading, [createRuleLoading, putRuleLoading, ruleLoading]);
  const [isDelayedLoading] = useDelayedValue(isLoading, 500, false);

  useEffect(
    () => () => {
      // Clean up and reset RuleState. Needed for when navigation
      if (import.meta.hot) {
        setRuleState(null);
      }
    },
    [setRuleState],
  );

  // if (ruleDirty || filtersDirty || pendingListingUpdates.length > 0) {
  //   return false;
  // }
  // return true;
  const ruleNavigationValidation = useCallback(() => validateDirtyRuleState(), [validateDirtyRuleState]);

  return {
    saveRule,
    isLoading: isDelayedLoading,
    isLoadingLive: isLoading,
    isLocalRule,
    createRule,
    updateInventoryListing,
    validateDirtyRuleState,
    applyRuleToGroupedListings,
    triggerSaveRule,
    disbandRule,
    addCurrentRuleToListing,
    formKey,
    resetForm,
    processPendingUpdates,
    setFormKey,
    pendingListingUpdatesByProperty,
    calculatePricerStatusId,
    form,
    submit,
    listingsByRuleId,
    ruleNavigationValidation,
    saveRuleTemplate,
  };
};

export const { Provider: RuleStateProvider, useSelector: useRuleState } = yasml(RuleState);

RuleStateProvider.displayName = 'RuleState.Provider';
